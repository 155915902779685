<template>
    <v-container fluid class="news-detail-page">
        <v-row no-gutters justify="center">
            <v-col cols="12" class="image-news">
                <v-img cover :src="'/Image/mock1.jpg'" max-height="400px"></v-img>
                <img src="/Image/close.png" alt="" @click="$router.push('/News')">
            </v-col>
            <v-col cols="12" class="content">
                <v-row justify="center">
                    <v-col cols="12" class="header-news">
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                        mollit anim.
                    </v-col>
                    <v-col cols="12">
                        <div class="d-flex align-center">
                            <img src="/Image/time@2x.png" alt style="height:20px;width:20px;"/>
                            <span class="ml-3 date-news">June 2, 2017</span>
                        </div>
                    </v-col>
                    <v-col cols="12" class="title-news">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio
                    </v-col>
                    <v-col cols="12" class="subtitle-news">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                        dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
                        sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                        invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                        sit
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "NewsDetail",
    };
</script>

<style lang="scss">
    .news-detail-page {
        height: 100%;
        padding: 0px;
        .image-news {
            position: relative;
            img {
                position: absolute;
                top: 1.5vh;
                right: 1.5vh;
            }
        }
        .content {
            max-width: 700px;
            padding: 0px 16px;

            .header-news {
                font-weight: 800;
                font-size: 20px;
            }

            .date-news {
                color:#969696;
                font-size: 12px;
            }

            .title-news {
                font-weight: 800;
                font-size: 14px;
            }

            .subtitle-news {
                font-weight: 400;
                color:#969696;
                font-size: 14px;
            }
        }
    }
</style>